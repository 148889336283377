/**
 * Created by StefanS on 2015-Aug-20
 */

const _file = 'GlassDimmer';
const _double_include = $(window).data('included-' + _file);
$(window).data('included-' + _file, true);

var GlassDimmer = (function ($) {
  function getAllDimmers(container) {
    return $(container).find(".dimmer").not('.react-dimmer');
  }

  function getDimmer() {
    return getAllDimmers('body').first();
  }

  function dim() {
    var $dimmer = getDimmer();
    $dimmer.addClass('dimmed');

    setTimeout(function() {
      $dimmer.data('take-clicks', 'yes');
    }, 700);
  }

  function unDim() {
    getAllDimmers('body').each(function() {
      $(this).removeClass("dimmed");
      $(this).data('take-clicks', 'no');
    });
  }

  function onDimmerClick(callback) {
    getDimmer().on('click', function(e) {
      if ($(this).data('take-clicks') == 'yes' && $(this).hasClass('dimmed') && this == e.target) {
        callback(e);
      }
    });
  }

  // Return API for other modules
  return {
    dim:           dim,
    unDim:         unDim,
    onDimmerClick: onDimmerClick,
    getDimmer:     getDimmer,
    getAllDimmers: getAllDimmers
  };
})(jQuery);

export default GlassDimmer
