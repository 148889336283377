import GlassDimmer from 'glass/modules/dimmer'
import CanvasForms from 'glass/modules/forms'

const _file = 'GlassStacks';
const _double_include = $(window).data('included-' + _file);
$(window).data('included-' + _file, true);

var GlassStacks = (function($){
  function activeStack() {
    return $('.stacks-wrapper .stack-item').last();
  }

  function show(options = {}) {
    var $new_stack_item = $('#stack-item-template .stack-item').clone();
    $new_stack_item.data('openerBtn', options['openerBtn']);
    var $action_wrappers = $new_stack_item.find('.actions-bar');

    $action_wrappers.not('.cms-top-bar').hide();

    if (options['selector']) {
      $new_stack_item.empty();
      $new_stack_item.append($(`${options['selector']} .stack-item`).children());
      $new_stack_item.data('selector', options['selector']);
    }
    else if (options['url']) {
      $.ajax({
        url: options['url'],
        beforeSend: function( xhr ) {
          xhr.setRequestHeader('Accept', 'text/html');
        },
        success: function(data) {
          var $stack_body = $new_stack_item.find('.stack-body');
          $stack_body.empty();
          $stack_body.append($(data).find('.admin-content-wrapper').children());
          $stack_body.find('.cms-top-bar').addClass('actions-bar').removeClass('cms-top-nav'); // TODO: work with the frontend guys, maybe we can get rid of this
          var $bottom_bar = $new_stack_item.find('.actions-bar.bottom-bar');
          $action_wrappers = $new_stack_item.find('.actions-bar');
          if ($bottom_bar.length > 0) {
            $new_stack_item.find('.stack-inner').addClass('with-bottom-bar');
          }
          else {
            $new_stack_item.find('.stack-inner').removeClass('with-bottom-bar');
          }

          var stack_index = $('.stacks-wrapper .stack-item').length;
          $stack_body.find('#page-preview, #admin-settings, #card-preview').each(function() {
            $(this).attr('id', $(this).attr('id') + '-' + stack_index); // These are for the scrolling container for froala, they need to be unique
          });

          options['bare'] ? $action_wrappers.hide() : $action_wrappers.show();

          $(document).trigger('content-ready', $stack_body);
          if (options['undeletable']) {
            setDeletable(false);
          } else {
            setDeletable(true);
          }
        }
      })
    }
    else {
      // just load a blank one, the caller will fill it in
    }

    if (options['selector'] || !options['url']) {
      options['bare'] ? $action_wrappers.hide() : $action_wrappers.show();
    }

    if ($('.stacks-wrapper .stack-item').length) {
      var stack_gutter = 200 / ($('.stacks-wrapper .stack-item').length + 1);
      $('.stacks-wrapper .stack-item').each(function(i, el){
        $(el).css({'left': (stack_gutter * (i + 1)) + 'px', width: 'calc(100% - ' + (stack_gutter * (i + 1)) + 'px)'});
      });
    }

    $('.stacks-wrapper').append($new_stack_item);
    $(document).trigger('content-ready', $new_stack_item);
    if (options['undeletable']) {
      setDeletable(false);
    } else {
      setDeletable(true);
    }
    GlassDimmer.dim();
    $('body').addClass('stacks-active');
    setTimeout(function() {
      // give the browser a chance to render the DOM first, then set active class for animation
      $($new_stack_item.addClass('active'));
    }, 5);

    if (options['close_callback']) {
      onClose(options['close_callback']);
    }

    return $new_stack_item;
  }

  function setDeletable(val = true) {
    var $btns = activeStack().find('.modal-close.delete-on-close');
    if (val) {
      $btns.removeClass('d-none');
    }
    else {
      $btns.addClass('d-none');
    }
  }

  function setSavable(val = true) {
    var $btns = activeStack().find('.modal-close.save-on-close');
    if (val) {
      $btns.removeClass('d-none');
    }
    else {
      $btns.addClass('d-none');
    }
  }

  function displayError(message) {
    var $elem = activeStack().find('.error-message');
    if (message) {
      $elem.text('Error: ' + message);
      $elem.removeClass('d-none');
    }
    else {
      $elem.addClass('d-none');
    }
  }

  function onClose(callback) {
    activeStack().data('close-callback', callback);
  }

  function hide(options = {}) {
    var $active_stack = options['stack_item'] || activeStack();
    options['stack_item'] = $active_stack;

    if (!$active_stack || $active_stack.parents('.stacks-wrapper').length == 0) {
      return null;
    }

    var allow_hide = true;

    if (options['with_callback']) {
      options['openerBtn'] = $active_stack.data('openerBtn');
      [$active_stack.data('close-callback'), options['openerBtn'] ? options['openerBtn'].data('close-callback') : null].forEach(function(callback) {
        if (allow_hide && callback) {
          allow_hide = callback(options);
        }
      });
    }

    if (allow_hide) {
      if ($active_stack.data('selector')) {
        $(`${$active_stack.data('selector')} .stack-item`).append($active_stack.children());
      }
      $active_stack.remove();

      if ($('.stacks-wrapper .stack-item').length == 0) {
        $('body').removeClass('stacks-active');
        GlassDimmer.unDim();
      }
    }

    return $active_stack;
  }

  function reloadStack() {
    replaceStack()
  }

  function replaceStack(url = null) {
    var $opener_btn = activeStack().data('openerBtn');
    if (url) {
      $opener_btn.data('url', url);
    }
    hide({with_callback: false, save: false})
    $opener_btn.trigger('click');
  }

  $(document).on('content-ready', _double_include ? null : function (e, element) {
    $(element).find('.modal-close').each(function() {
      if ($(this).parents('.stacks-wrapper').length > 0) {
        $(this).click(function(e) {
          hide({with_callback: true, save: $(this).hasClass('save-on-close'), delete: $(this).hasClass('delete-on-close')});

          if ($('.stacks-wrapper .stack-item').length) {
            var stack_gutter = 200 / ($('.stacks-wrapper .stack-item').length);
            $('.stacks-wrapper .stack-item').each(function(i, el){
              $(el).css({'left': (stack_gutter * (i + 1)) + 'px', width: 'calc(100% - ' + (stack_gutter * (i + 1)) + 'px)'});
            });
          }
        });
      }
    });

    $(element).find('.reload-stack, .replace-stack').click(function(e) {
      replaceStack($(this).data('url'));
    });

    $(element).find('form.ajax-form').each(function() {
      var $form = $(this);
      var $stack_item = $form.parents('.stack-item');

      if ($form.find('.form-actions').length > 0 && $stack_item.length > 0) {
        $stack_item = $stack_item.first();

        $form.data('on-success-about-to-redirect', function(context) {
          // var updateOnClose = ... selector
          // if (updateOnClose){
          //   CanvasForms.ajaxUpdateContent(updateOnClose);
          // }


          hide({stack_item: $stack_item, with_callback: true, save: true, form: $form, redirect_url: context['redirect_url']});
          return 'no-redirect';
        });
      }
    });

    $(element).find('.load-stack-item').click(function(e) {
      e.preventDefault()
      show({selector: $(this).data('selector'), url: $(this).data('url') || $(this).attr('href'), undeletable: $(this).data('undeletable'), openerBtn: $(this)})
    });

    var $initial_stack_opener = $(element).find('.load-stack-item[data-open-on-page-load="true"]').first();
    if ($initial_stack_opener) {
      setTimeout(function() { // changes the DOM so needs to happen after the initial 'body' content-ready
        $initial_stack_opener.click();
      }, 1);
    }
  });

  GlassDimmer.onDimmerClick(function() {
    if (activeStack().length > 0) {
      hide({with_callback: true, save: false});
    }
  });

  // Return API for other modules
  return {
    activeStack:  activeStack,
    show:         show,
    hide:         hide,
    setDeletable: setDeletable,
    setSavable:   setSavable,
    onClose:      onClose,
    displayError: displayError,
    reloadStack:  reloadStack,
    replaceStack: replaceStack,
  };
})(jQuery);

export default GlassStacks;
